<template>
    <el-dialog :visible="visible"  custom-class="hscode-detail-dialog" @close="visible=false" append-to-body :title="null">
        <template slot="title">
            <img :src="ai3DIcon" alt="" class="ai-icon" width="200">
            <div class="name">详情</div>
        </template>
        <CodeDesc ref="codeDescRef"></CodeDesc>
    </el-dialog>
</template>
<script>
import CodeDesc from './code-desc.vue';
export default {
    name: 'codeDetail',
    components: {
        CodeDesc
    },
    data() { 
        return {
            visible: false,
            ai3DIcon: require('@/assets/img/analysis/ai-3d-icon.png'),
        }
    },
    methods: {
        open(code) { 
            this.visible = true
            this.$nextTick(() => { 
                this.$refs.codeDescRef.init(code);
            })
        }
    }
}
</script>

<style lang="stylus">
.hscode-detail-dialog{
    border-radius: 10px;
    .el-dialog__header{
        height:20px;
        border-radius:10px 10px 0 0;
        position:relative;
        background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231)); 
    }
    .el-dialog__body{
        height: 60vh;
        background:linear-gradient(#91eae4, 3%, white, white, white, white) !important;
        border-radius: 0 0 10px 10px;
        overflow:auto;
    }
 .el-dialog__headerbtn{
        top:10px;
        font-size: 24px;
      }
       .el-dialog__close{
        color:#fff !important;
      }
    .ai-icon{
        position: absolute;
        left: -60px;
        top:-125px;
    }
    .name{
        position: absolute;
        left:calc(50% - 20px);
        color: #fff;
        font-size: 24px;
        top: 10px;
    }
}
</style>