<template>
    <div class="history-element-wrap" v-if="listData.length">
        <div class="result-group-title" style="margin-bottom: 40px">
            以下是与【{{ searchGname }}】相似品名的申报要素示例：
        </div>
        <TableBorder>
            <el-table :data="listData" border>
                <el-table-column
                    v-for="col in historyElementColumn"
                    :label="col.label"
                    :prop="col.prop"
                    :key="col.prop"
                    :width="col.width"
                    :fixed="col.fixed"
                    :min-width="col.minWidth"
                >
                    <template slot-scope="scope">
                        <div
                            v-if="col.prop === 'codeTs'"
                            class="hscode-col-wrap"
                        >
                            <span>{{ scope.row[col.prop] }}</span>
                            <img
                                v-clipboard:copy="scope.row[col.prop]"
                                v-clipboard:success="copySucc"
                                src="@/assets/icon/copy.svg"
                            />
                        </div>
                        <div
                            v-else-if="
                                col.prop === 'codeDesc' &&
                                detailEnum[scope.row.codeTs]
                            "
                        >
                            {{ detailEnum[scope.row.codeTs].gname }}
                        </div>
                        <div
                            v-else-if="
                                col.prop === 'expTaxBack' &&
                                detailEnum[scope.row.codeTs]
                            "
                        >
                            {{
                                detailEnum[scope.row.codeTs].expTaxBack
                                    ? `${
                                          detailEnum[scope.row.codeTs]
                                              .expTaxBack
                                      }%`
                                    : ""
                            }}
                        </div>
                        <div v-else>{{ scope.row[col.prop] }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="60"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleGoDetail(scope.row)"
                        >
                            详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </TableBorder>

        <code-detail ref="codeDetailRef"></code-detail>
    </div>
</template>

<script>
import { getHsElementByGName, batchGetHscodeList } from "@/api/analysis";
import { historyElementColumn } from "../constant";
import { apiCallback } from "@/utils";
import codeDetail from "./code-detail.vue";
import TableBorder from "./table-border.vue";

export default {
    name: "HistoryElementTable",
    data() {
        return {
            historyElementColumn,
            listData: [],
            searchGname: "",
        };
    },
    components: {
        codeDetail,
        TableBorder,
    },
    props: {
        detailEnum: {
            type: Object,
        },
    },
    methods: {
        copySucc() {
            this.$message.success("复制成功！");
        },
        reset() {
            this.listData = [];
        },
        handleGoDetail({ codeTs }) {
            this.$refs.codeDetailRef.open(codeTs);
        },
        init(gName) {
            this.searchGname = gName;
            return new Promise((resolve) => {
                getHsElementByGName({
                    keyword: gName,
                })
                    .then((res) => {
                        const data = apiCallback(res);
                        this.listData = data;
                        if (data && data.length) {
                            const hsCodeArr = data.map((item) => item.codeTs);
                            const hsCodeSet = new Set(hsCodeArr);
                            const uniqueCodeArr = Array.from(hsCodeSet);
                            resolve(uniqueCodeArr);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch(() => {
                        resolve([]);
                    });
            });
        },
    },
};
</script>

<style lang="stylus">
.history-element-wrap{
    .hscode-col-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            margin-left: 4px;
            cursor: pointer;
        }
    }
    .result-group-title{
        margin-top: 8px;
        font-size:1.4rem;
        font-weight: bold;
    }
}
</style>
