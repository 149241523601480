<template>
    <div class="table-border-container">
        <img :src="aiIcon" alt="" width="100" class="ai-icon-img" />
        <div class="border-right"></div>
        <div class="border-left"></div>
        <div class="border-top"></div>
        <div class="border-bottom"></div>
        <div class="border-block-right-top-1"></div>
        <div class="border-block-right-top-2"></div>
        <div class="border-block-left-top-1"></div>
        <div class="border-block-left-top-2"></div>
        <div class="border-block-right-bottom-1"></div>
        <div class="border-block-right-bottom-2"></div>
        <div class="border-block-left-bottom-1"></div>
        <div class="border-block-left-bottom-2"></div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "CodeDesc",
    data() {
        return {
            aiIcon: require("@/assets/img/analysis/ai-icon.png"),
        };
    },
}
</script>
<style scoped lang="stylus">
.table-border-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    /deep/ .el-table--border th.el-table__cell{
        background-color: #E3F2FD ;
        color:#295878;
        font-weight: 1000;
    }
    /deep/ .el-table__body .el-table__row {
        background-color:#BBDEFB;
        color:#295878;
        font-weight: bold;
        &:nth-child(2n){
            background-color:#90CAF9;
        }
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background: #7f7fd5;
        color:#99ffff;
    }
    /deep/ .el-table .hover-row >td.el-table__cell{
        background: #7f7fd5;
        color:#99ffff;
    }
    /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border-bottom:1px solid #d3fbff;
    }
    
    /deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right:1px solid #d3fbff;
    }
    
        
    
}

.ai-icon-img {
    position: absolute;
    top: -65px;
    left: -65px;
    width:75px;
    height:75px;
}
.border-right {
    position: absolute;
    right: -5px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #72feff;
}
.border-top {
    position: absolute;
    left: 0;
    top: -5px;
    width: 100%;
    height: 2px;
    background-color: #72feff;
}
.border-left {
    position: absolute;
    left: -5px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #72feff;
}
.border-bottom {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #72feff;
}
.border-block-right-top-1 {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 25px;
    height: 5px;
    background-color: #70f8ff;
}
.border-block-right-top-2 {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 5px;
    height: 25px;
    background-color: #70f8ff;
}
.border-block-left-top-1 {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 25px;
    height: 5px;
    background-color: #70f8ff;
}
.border-block-left-top-2 {
    position: absolute;
    left: -5px;
    top: -5px;
    width: 5px;
    height: 25px;
    background-color: #70f8ff;
}
.border-block-right-bottom-1 {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 25px;
    height: 5px;
    background-color: #70f8ff;
}
.border-block-right-bottom-2 {
    position: absolute;
    right: -5px;
    bottom: -5px;
    width: 5px;
    height: 25px;
    background-color: #70f8ff;
}
.border-block-left-bottom-1 {
    position: absolute;
    left: -5px;
    bottom: -5px;
    width: 25px;
    height: 5px;
    background-color: #70f8ff;
}
.border-block-left-bottom-2 {
    position: absolute;
    left: -5px;
    bottom: -5px;
    width: 5px;
    height: 25px;
    background-color: #70f8ff;
}
</style>
