<template>
    <el-dialog :visible="visible" @close="close" top="5vh" width="90%">
        <code-table ref="codeTableRef" :animate="false" :emptyHide="false"></code-table>
    </el-dialog>
</template>

<script>
import CodeTable from './code-table.vue';

export default {
    name: 'CodeDialog',
    components: {
        CodeTable
    },
    data() { 
        return {
            visible: false,
            loading: false
        }
    },
    methods: {
        open(code) { 
            this.visible = true
            this.loading = true
            this.$nextTick(() => { 
                this.$refs.codeTableRef.loadList({
                    code
                }).then(() => {
                    this.loading = false
                })
            })
        },
        close() { 
            this.visible = false;
            this.$refs.codeTableRef.reset();
        }
    }
}
</script>