<template>
    <div class="code-desc-wrap">
            <div class="desc-item" v-for="col in detailColumn" :key="col.prop">
                <div class="label">{{ col.label }}:</div>
                <div v-if="col.prop === 'ciq'" class="group-value">
                    <div
                        class="group-value-item"
                        v-for="ciqItem in detailData.ciq"
                        :key="ciqItem.statcode"
                    >
                        {{ `${ciqItem.statcode}：${ciqItem.ciqname}` }}
                    </div>
                </div>
                <div v-else-if="col.prop === 'controlMark'" class="group-value">
                    <div
                        class="group-value-item"
                        v-for="markItem in detailData.controlMark"
                        :key="markItem.value"
                    >
                        {{ markItem.value }}：{{ markItem.label }}
                    </div>
                </div>
                <div v-else-if="col.prop === 'expTaxBack'" class="value">
                    {{
                        detailData.expTaxBack ? `${detailData.expTaxBack}%` : ""
                    }}
                </div>
                <div v-else-if="col.prop === 'companyCase'" class="value">
                    {{ companyCase }}
                </div>
                <div class="value" v-else>{{ detailData[col.prop] }}</div>
            </div>
    </div>
</template>

<script>
import { getHsCodeDetail, getHscodeCompanyCase } from "@/api/analysis";
import { apiCallback } from "@/utils";
import { detailColumn } from "../constant";
import TableBorder from "./table-border.vue";

export default {
    name: "CodeDesc",
    data() {
        return {
            detailColumn,
            curCode: "",
            detailData: {},
            companyCase: "",
            aiIcon: require("@/assets/img/analysis/ai-icon.png"),
        };
    },
    components: {
        TableBorder,
    },
    methods: {
        loadDetail(code) {
            const reqCode = code || this.curCode;
            getHsCodeDetail({ hsCode: reqCode }).then((res) => {
                const { ciq, controlMark, ...restData } = apiCallback(res);
                this.loadCompanyCase(code);
                const parseCiqData = JSON.parse(ciq);
                this.detailData = {
                    ...restData,
                    ciq: parseCiqData.rows,
                    controlMark: this.getControlMarkNameArray(controlMark),
                };
            });
        },
        getControlMarkNameArray(val) {
            const that = this;
            const controlMarkArr = [];
            for (let i = 0; i < val.length; i++) {
                controlMarkArr.push(val[i]);
            }
            const result = controlMarkArr.map((mark) => {
                const curMark =
                    that.$store.state.txtdata.documentsAttachedList.find(
                        (item) => item.value === mark,
                    );
                return curMark;
            });
            return result;
        },
        loadCompanyCase(code) {
            getHscodeCompanyCase({ hsCode: code }).then((res) => {
                const data = apiCallback(res);
                if (data) {
                    this.companyCase = data;
                }
            });
        },
        init(code) {
            this.curCode = code;
            this.loadDetail(code);
        },
        reset() {
            (this.curCode = ""),
                (this.detailData = {}),
                (this.companyCase = "");
        },
    },
};
</script>

<style lang="stylus" scoped>
.code-desc-wrap{
    width: 97%;
    margin: 0 auto;
    margin-top:10px;
    font-size:14px;
    border:2px solid #86a8e7;
    .desc-item{
        display: flex;
        border: 1px solid #86a8e7;
        background-color: #e3f2fd;
        &:nth-child(2n){
            background-color:#90CAF9;
        }
        .label{
            width: 90px;
            font-weight: 1000;
            text-align: right;
            padding: 8px;
            background-color:#bbdefb;
            color:#295878;
            flex-shrink: 0;
            border-right: 1px solid #86a8e7;
        }
        .value,.group-value{
            padding: 8px 0;
            color: #295878;
            font-weight: bold;
            flex-grow: 1;
            padding-left:12px;
        }
        .group-value-item{
            line-height: 24px;
        }
    }

}
</style>
