export const paginationMixin = {
    data() {
        return {
            curPageList: [], // 当前页数据
            pageNo: 1,
            pageSize: 10,
            total: 0,
            sizeArr: [10, 15, 20, 30, 50],
            paginationParam: {},
        };
    },
    methods: {
        updatePageList(list) {
            this.curPageList = list;
        },
        updateTotal(total) {
            this.total = total;
        },
        updatePaginationParam(param) {
            this.paginationParam = param;
        },
        changePageNo(no) {
            this.pageNo = no;
            this.loadList();
        },
        changePageSize(size) {
            this.pageSize = size;
            this.pageNo = 1;
            this.loadList();
        },
        resetPagination() {
            this.pageNo = 1;
            this.pageSize = 10;
            this.total = 0;
            this.curPageList = [];
        },
    },
};
