<template>
    <div class="code-analysis-body">
        <div
            v-if="!searchData.code"
            class="animate__animated animate__fadeIn"
        >
            <el-empty
                :image="require('../../../assets/img/empty.png')"
                :image-size="300"
                :description="emptyDesc"
            ></el-empty>
        </div>
        <code-table
            v-show="!singleResult"
            ref="codeTableRef"
        ></code-table>
        <code-desc
            v-show="singleResult"
            ref="codeDescRef"
        ></code-desc>
    </div>
</template>

<script>
import CodeTable from './code-table.vue';
import CodeDesc from './code-desc.vue';

export default {
    name: 'AnalysisCodeResult',
    components: {
        CodeTable,
        CodeDesc
    },
    data() { 
        return {
            emptyDesc: "暂无数据...",
            searchData: {
                code:''
            },
            searchResult: []
        }
    },
    computed: {
        singleResult() { 
            return this.searchResult.length === 1
        }
    },
    methods: {
        search(data) { 
            this.searchData.code = data.gName
            this.$emit('loading-change', true)
            this.$refs.codeTableRef.loadList({ code: data.gName }).then((list) => {
                this.searchResult = list;
                if (list.length === 1) { 
                    const curCode = list[0].codeTs;
                    this.$refs.codeDescRef.init(curCode)
                } 
                this.$emit('loading-change',false)
            })
        },
        clear() { 
            this.searchData.code = '';
            this.listData = [];
            this.searchResult = [];
            this.$refs.codeTableRef.reset();
            this.$refs.codeDescRef.reset();
        },
    },
}
</script>

<style lang="stylus" scoped>
.code-analysis-body{
    width:70%;
    min-height: 50vh;
    margin: 0 auto;
    background-color: #fff;;
    padding: 24px;
    overflow: auto;
    border-radius: 0 0 4px 4px;
}
</style>