<template>
    <div class="category-tree-wrap">
        <el-collapse v-model="activeCategory" accordion>
            <el-collapse-item v-for="firstLevelCategory in treeData" :key="firstLevelCategory.id">
                <template slot="title">
                    <span>{{ firstLevelCategory.name }}</span>
                    <el-button v-if="!firstLevelCategory.noteIsEmpty" type="text" @click="(e) => showNoteDialog(e,firstLevelCategory,1)" :disabled="!firstLevelCategory.annotation">[类注]</el-button>
                </template>
                <div class="sub-collapse-wrap">
                    <el-collapse v-model="subActiveCategory" accordion>
                        <el-collapse-item 
                        v-for="secondLevelCategory in firstLevelCategory.hsDirectory" :key="secondLevelCategory.id" :title="secondLevelCategory.name">
                            <template slot="title">
                                <span style="margin-right: 8px;">{{ secondLevelCategory.name }}</span>
                                <el-button v-if="!secondLevelCategory.noteIsEmpty" type="text" size="small" @click="(e) => showNoteDialog(e,secondLevelCategory,2)" :disabled="!secondLevelCategory.annotation">[章注]</el-button>
                            </template>
                            <div class="third-level-category-item" v-for="thirdLevelCategory in secondLevelCategory.hsDirectory"
                            :key="thirdLevelCategory.id">
                                <span style="margin-right: 8px;">{{ thirdLevelCategory.code }}</span>
                                <el-button type="text" size="small" @click="(e) => showNoteDialog(e,thirdLevelCategory,3)" :disabled="!thirdLevelCategory.annotation">[子目注释]</el-button>
                                <el-button type="text" size="small" @click="handleTreeNodeDetail(thirdLevelCategory)">[详情]</el-button>
                                <span style="margin-left: 8px;">{{ thirdLevelCategory.name }}</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </el-collapse-item>
        </el-collapse>
        <NoteDialog ref="noteDialogRef"></NoteDialog>
        <CodeDialog ref="codeDialogRef"></CodeDialog>
    </div>
</template>

<script>
import { apiCallback } from '@/utils';
import NoteDialog from './note-dialog.vue';
import CodeDialog from './code-dialog.vue';
import { getHscodeCategoryNoteIsEmpty } from '../../../utils/txtdata';
export default {
    name: 'CategoryTree',
    components: {
        NoteDialog,
        CodeDialog
    },
    data() { 
        return {
            activeCategory: '',
            subActiveCategory: '',
        }
    },
    computed: {
        treeData() { 
            const result = this.$store.state.txtdata.hscodeCategory.map(item => { 
                const { hsDirectory } = item
                if (hsDirectory && hsDirectory.length) {
                    return {
                        ...item,
                        noteIsEmpty: getHscodeCategoryNoteIsEmpty(item.id),  // 是否有类注
                        hsDirectory: item.hsDirectory.map(hs => {
                            return {
                                ...hs,
                                noteIsEmpty: getHscodeCategoryNoteIsEmpty(hs.id), // 是否有章注
                            }
                        })
                    }
                } else { 
                    return {
                        ...item,
                        noteIsEmpty: getHscodeCategoryNoteIsEmpty(item.id)
                    }
                }
            })
            return result
        }
    },
    methods: {
        // type 1-类注、2-章注、3-字母注释
        showNoteDialog(e, { id }, type) { 
            e.stopPropagation();
            if (id) { 
                this.$refs.noteDialogRef.open(id,type)
            }
        },
        handleTreeNodeDetail(data) { 
            this.$refs.codeDialogRef.open(data.code)
        }
    },
    mounted() { 
        if (!this.treeData.length) {
            this.$store.dispatch('txtdata/updateHscodeCategoryData')
        } else { 
            console.log(this.$store.state.txtdata.hscodeCategory,'-----aaa')
        }
    }
}
</script>

<style lang="stylus">
.category-tree-wrap{
    width:70%;
    min-height: 50vh;
    margin: 0 auto;
    background-color: #fff;;
    padding: 24px;
    overflow: auto;
    border-radius: 0 0 4px 4px;
    .el-collapse{
        border: 1px solid #e6e6e6;
    }
    .el-collapse-item__header{
        font-size: 14px;
        font-weight: bold;
        background-color: #f2f2f2;
        padding-left: 8px;
        border-bottom: 1px solid #e6e6e6;
    }
    .el-collapse-item__content{
        padding-bottom: 0;
    }
    .sub-collapse-wrap{
        padding: 8px 16px;
        .el-collapse-item__header{
            font-size: 12px;
        }
    }
    .third-level-category-item{
        padding: 8px 16px;
    }
}
</style>