export const hsTableColumn = [
    {
        prop: "codeTs",
        label: "hscode",
        align: "center",
        fixed: "left",
        width: 125,
    },
    {
        prop: "gname",
        label: "编码描述",
        align: "left",
        minWidth: 200,
    },
    {
        prop: "element",
        label: "申报要素",
        align: "left",
        minWidth: 400,
    },
    {
        prop: "unit1",
        label: "第一单位",
        align: "center",
        width: 80,
    },
    {
        prop: "unit2",
        label: "第二单位",
        align: "center",
        width: 80,
    },
    {
        prop: "controlMark",
        label: "监管条件",
        align: "center",
        width: 80,
    },
    {
        prop: "inspMonitorCond",
        label: "检验检疫",
        align: "center",
        width: 80,
    },
    {
        prop: "expTaxBack",
        label: "出口退税",
        align: "center",
        width: 80,
    },
];

export const detailColumn = [
    {
        prop: "codeTs",
        label: "hscode",
    },
    {
        prop: "gname",
        label: "编码描述",
    },
    {
        prop: "noteS",
        label: "编码解释",
    },
    {
        prop: "companyCase",
        label: "企业归类实例",
    },
    {
        prop: "ciq",
        label: "ciq代码",
    },
    {
        prop: "element",
        label: "申报要素",
    },
    {
        prop: "unit1",
        label: "第一单位",
    },
    {
        prop: "unit2",
        label: "第二单位",
    },
    {
        prop: "controlMark",
        label: "监管条件",
    },
    {
        prop: "inspMonitorCond",
        label: "检验检疫",
    },
    {
        prop: "expTaxBack",
        label: "出口退税",
    },
    {
        prop: "dangerFlag",
        label: "是否为危险品",
    },
    {
        prop: "outDutyTypeFlag",
        label: "是否有关税",
    },
];

export const historyElementColumn = [
    {
        prop: "codeTs",
        label: "hscode",
        width: 125,
        fixed: "left",
    },
    {
        prop: "codeDesc",
        label: "编码描述",
        width: 300,
    },
    {
        prop: "gname",
        label: "中文品名",
        width: 150,
    },
    {
        prop: "expTaxBack",
        label: "出口退税",
        width: 80,
        align: "center",
    },
    {
        prop: "gmodel",
        label: "申报要素示例",
        minWidth: 400,
    },
];
